/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
import "./styles.scss"

import React, { useState, useEffect } from "react"
import { withCookies } from "react-cookie"

const Popup = ({ lang = "pl", cookies }) => {
  const [modal, setModal] = useState(false)

  const key = `cookie-accept-trust-competition-popup-${lang}`

  useEffect(() => {
    const isAcceptedCoookie = !!cookies.get(key)
    !isAcceptedCoookie && setModal(true)
  }, [cookies, key])

  const acceptPopup = e => {
    e.preventDefault()

    const promiseSetCookie = new Promise(resolve =>
      resolve(
        cookies.set(key, "active", {
          path: "/",
          maxAge: 900,
        })
      )
    )

    promiseSetCookie.then(() => {
      setModal(false)
    })
  }

  return (
    modal && (
      <button className="competition-popup" onClick={acceptPopup}>
        <div className="competition-popup__wrapper">
          <div className="competition-popup__inner">
            <img
              src={require("assets/images/competition-popup.jpg").default}
              alt=""
            />
          </div>
        </div>
      </button>
    )
  )
}

export default withCookies(Popup)
